import React from "react";
var Aboutme = require('../image/Eric_Andrea.jpg');
var AboutUs = require('../image/AboutMe.png');
var Fidelity = require('../image/fidelity.png');
var USAA = require('../image/usaa.png');
var Capone = require('../image/capone.png');
var Bofa = require('../image/boa.png');
var Ps = require('../image/ps.png');
var Google = require('../image/google.png');


export default function About() {
    return (
        <section id="about" className="text-vintage-dblue bg-white">
            <div className="container mx-auto">
            <div className="grid grid-cols-6 gap-3 sm:grid-cols-1 ">
            <div className="row-span-3 bg-white p-3 rounded-md"><h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-vintage-dblue pt-24">About Me</h1></div>
            <div className="col-span-6 bg-white p-3 rounded-md">
                <p className="mb-8 leading-relaxed sm:text-lg text-base">Hello there new friend! My name is Eric, and I’m a Digital Accessibility Leader and Design Strategist 
                    with over 10 years of experience building and implementing processes that make accessible design and development more efficient. 
                    My focus is on empowering teams with the knowledge and tools to help them create better, more accessible, user experiences. My background has afforded me the 
                    awesome opportunities to work with companies such as USAA, Fidelity, Google, Capital One, Inuit (to name drop a few), as well as federal, state & local governments. </p>
                <p className="mb-8 leading-relaxed sm:text-lg text-base">While at USAA, I stood up a review process for our design language system, focusing on usage and accessibility 
                    implementation, which resulted in components for the library that were 100% free of accessibility defects. </p>
                <p className="mb-8 leading-relaxed sm:text-lg text-base">
                    I also:
                    <ul>
                        <li>Conducted user research workshops and synthesized feedback into actionable insights to prioritize and drive creation of and enhancements to the overall design processes at USAA. </li>
                        <li>The "Accessibility for Designers" course I helped create ensured that designers across the organization would approach their designs with an accessibility first mindset, resulting in a +40% reduction in first time defects being found during reviews.</li>
                    </ul>
                    </p>
                    <p className="mb-8 leading-relaxed sm:text-lg text-base">At Fidelity, I continue to work with cross-functional teams in design, development and QE to establish, socialize and find ways to enforce a 
                        true end to end process to include accessibility at every stage. This established (and ever evolving) process has resulted in a reduction of defects found at the later stages of the experience lifecycle. 
                        I have also: 
                    <ul>
                        <li>Worked with business leaders to create two new accessibility related KPIs tied to a comprehensive training program rollout and ensuring experiences adhere to our testing strategy </li>
                        <li>Developed and executed a leadership workshop delivered to over 100 executives using hands on exercises to promote inclusion and accessibility in their day to day responsibilities. </li>
                    </ul>
                    </p>
                    <p className="mb-8 leading-relaxed sm:text-lg text-base">I am looking for a role where I can apply my years of experience in strategy and process to give designers and their dev partners the tools and guidance needed to create engaging, accessible experiences. Drop me a message or let's connect and chat!</p>
            </div>
            </div>
            <div className="col-span-6 bg-white p-3 pt-8 pb-8 rounded-md"></div>
        </div>
    </section>
    );

}