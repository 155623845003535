import React from "react";
import { FaFacebook } from 'react-icons/fa';
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaCamera } from "react-icons/fa";

export default function Banner() {
    return (
        <div className="container-fluid banner-bgimage h-screen bg-violet" id="banner">
            <div className="grid h-min place-items-center">
            <div className="absolute inset-x-0 top-60 bottom-0">
            <h1 className="title-font sm:text-5xl text-4xl mb-4 font-medium text-vintage-babyblue text-center">
                        HELLO,&nbsp;&nbsp;
                        <br className="hidden lg:inline-block" />
                        <br className="hidden lg:inline-block" />
                        I'm Eric Lyons.
            </h1>
            <div class="flex flex-row justify-center text-5xl text-white dark:text-white sm:mt-0 pt-12">
            <p className="mb-8 leading-relaxed text-2xl text-vintage-babyblue pt-12 text-center">Digital Accessibility Leader and Design Strategist | Cross-functional maze runner and process driver | 10 plus years of experience helping companies empower teams to create better user experiences</p>
            </div>

            
    </div>
                <div className="flex justify-center absolute inset-x-0 bottom-24">
                    <a href="#contact" className="button-babyblue inline-flex text-vintage-dblue border-2 py-2 px-6 hover:button-dblue hover:text-vintage-lblue focus:button-dblue focus:text-vintage-lblue rounded text-lg">
                    Contact Me
                    </a>
                    <a href="#experience" className="button-babyblue ml-4 inline-flex text-vintage-dblue border-2 py-2 px-6 hover:button-dblue hover:text-vintage-lblue focus:button-dblue focus:text-vintage-lblue rounded text-lg">See My Past Work</a>
                </div>
            </div>
        </div>
    );
}