import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          alert('message sent successfully...');
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section id="contact" className="relative bg-vintage-blue">
      <div className="container px-5 py-10 mx-auto flex sm:flex-nowrap flex-wrap">
        <form ref={form} onSubmit={sendEmail}
          className="lg:w-3/3 md:w-2/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 text-vintage-babyblue">
          <h2 className="text-vintage-babyblue sm:text-4xl text-3xl mb-1 font-medium title-font">
            Contact Me
          </h2>
          <p className="leading-relaxed mb-6 text-vintage-babyblue sm:text-lg text-base mt-6">
            I'm a talker. Call or text me if you'd like to chat. But if you're a bit shy or old school, shoot me a message using the form below! Either way, be prepared for the most incredible conversation you'll have all day!
          </p>
          <div className="relative mb-4">
            <label htmlFor="user_name" className="leading-7 text-sm text-vintage-babyblue sm:text-lg text-base">
              Name
            </label>
            <input 
              type='text' 
              placeholder='Name' 
              name='user_name' 
              className="w-full bg-white rounded border border-white focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-vintage-babyblue py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="user_email" className="leading-7 text-sm text-vintage-babyblue sm:text-lg text-base">
              Email
            </label>
            <input 
              type='email' 
              placeholder='Email address' 
              name='user_email' 
              className="w-full bg-white rounded border border-white focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-vintage-babyblue py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="message"
              className="leading-7 text-sm text-vintage-babyblue sm:text-lg text-base">
              Message
            </label>
            <textarea 
              name='message' 
              type='text' 
              placeholder='Message'
              className="w-full bg-white rounded border border-white focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-vintage-babyblue py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
            />
          </div>
          <button 
            type='submit' 
            value='Submit' 
            id='input-submit' 
            className="text-vintage-dblue button-babyblue border-0 py-2 px-6 hover:button-dblue hover:text-vintage-lblue focus:button-dblue focus:text-vintage-lblue rounded text-lg focus:ring-2 focus:ring-indigo-900 hover:ring-2 hover:ring-indigo-900">
            Submit
          </button>
        </form>
      </div>
    </section>
  );
}

export default Contact;